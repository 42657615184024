import React from 'react'

function Ofertas() {
    return (
        <>
        <section className="my-favoritos back-trendy-2">
            <h1 className="text-center title-my-favoritos">Viajes Ofertas</h1>
       
        </section>
      

        <section className="section-top-ten">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                  
                <iframe className="iframe-mega" title="ofertas" src="https://q.megatravel.com.mx/tools/vi.php?&txtColor=000000&aColor=000000&ahColor=85dbdc&thBG=4f0367&thTxColor=fff&ff=2" width="100%" height="1200" border="0"align="left" allowtransparency="true" frameborder="0"> </iframe>
                </div> 
            </div> 
        </div>   
        </section>  
        
        </>
    )
}

export default Ofertas
